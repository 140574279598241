import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Post } from '../../../Page/Landing/Landing';
import './postpage.css';
import Carousel from '../../Carousel/Carousel';
import { useReactions } from '../../../Hooks/UseReactions';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import { useSwipeable } from 'react-swipeable';


type ReactionState = {
    happy: number;
    thumbs: number;
    calm: number;
};

const apiUrl = process.env.REACT_APP_API_URL ?? '';
const appUrl = process.env.REACT_APP_APP_URL;

const PostPage: React.FC = () => {
    const location = useLocation();
    const { postId } = useParams<{ postId: string }>();
    const [post, setPost] = useState<Post | null>(location.state as Post | null);
    const [surroundingPosts, setSurroundingPosts] = useState<{ prevPost: Post | null; nextPost: Post | null }>({
        prevPost: null,
        nextPost: null,
    });
    const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(null);
    const [swipeProgress, setSwipeProgress] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname.includes('bremen')) {
            document.body.className = 'bremen';
        } else {
            document.body.className = 'stanford';
        }
    }, []);

    useEffect(() => {
        if (!post && postId) {
            fetchUniquePost(parseInt(postId));
            fetchSurroundingPosts(parseInt(postId));
        } else if (post) {
            fetchSurroundingPosts(post.id);
        }
    }, [post, postId]);


    const fetchUniquePost = async (postId: number) => {
        try {
            const response = await axios.get<Post>(`${apiUrl}/api/post/${postId}`);
            setPost(response.data);
        } catch (error) {
            console.error('Error fetching post:', error);
        }
    };

    const fetchSurroundingPosts = async (currentPostId: number) => {
        try {
            const response = await axios.get<{ prevPost: Post | null; nextPost: Post | null }>(
                `${apiUrl}/api/surrounding/${currentPostId}`
            );

            const newSurroundingPosts: { prevPost: Post | null; nextPost: Post | null } = {
                prevPost: null,
                nextPost: null
            };

            if (response.data.nextPost) {
                const nextPostResponse = await axios.get<Post>(`${apiUrl}/api/post/${response.data.nextPost.id}`);
                newSurroundingPosts.nextPost = nextPostResponse.data;
            }

            if (response.data.prevPost) {
                const prevPostResponse = await axios.get<Post>(`${apiUrl}/api/post/${response.data.prevPost.id}`);
                newSurroundingPosts.prevPost = prevPostResponse.data;
            }

            setSurroundingPosts(newSurroundingPosts);

        } catch (error) {
            console.error('Error fetching surrounding posts:', error);
        }
    };



    const handleSwipeLeft = useCallback(async () => {
        if (surroundingPosts.nextPost) {
            setSwipeDirection('left');
            setTimeout(async () => {
                navigate(`/stanford/post/${surroundingPosts.nextPost!.id}`, { state: surroundingPosts.nextPost });
                const response = await axios.get<Post>(`${apiUrl}/api/post/${surroundingPosts.nextPost!.id}`);
                setPost(surroundingPosts.nextPost);
                await fetchSurroundingPosts(surroundingPosts.nextPost!.id);
                setSwipeDirection(null);
                setSwipeProgress(0);
            }, 300);
        }
    }, [surroundingPosts.nextPost, navigate]);

    const handleSwipeRight = useCallback(async () => {
        if (surroundingPosts.prevPost) {
            setSwipeDirection('right'); // Trigger swipe animation
            setTimeout(async () => {
                navigate(`/stanford/post/${surroundingPosts.prevPost!.id}`, { state: surroundingPosts.prevPost });
                setPost(surroundingPosts.prevPost);
                await fetchSurroundingPosts(surroundingPosts.prevPost!.id);
                setSwipeDirection(null);
                setSwipeProgress(0);
            }, 300);
        }
    }, [surroundingPosts.prevPost, navigate]);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (surroundingPosts.nextPost) {
                setSwipeProgress(-1);
            }
        },
        onSwipedRight: () => {
            if (surroundingPosts.prevPost) {
                setSwipeProgress(1);
            }
        },
        onSwiping: ({ deltaX }) => {
            if ((deltaX < 0 && surroundingPosts.nextPost) || (deltaX > 0 && surroundingPosts.prevPost)) {
                setSwipeProgress(deltaX / window.innerWidth);
            } else {
                setSwipeProgress(0); // Prevent swipe progress if the post is null
            }
        },
        onSwiped: (eventData) => {
            // Handle the swipe completion based on the direction and availability of posts
            if (eventData.dir === 'Left' && surroundingPosts.nextPost) {
                handleSwipeLeft();
            }
            if (eventData.dir === 'Right' && surroundingPosts.prevPost) {
                handleSwipeRight();
            }
        },
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    const handleAutoSwipeLeft = () => {
        if (surroundingPosts.nextPost) {
            setSwipeProgress(-1);
            handleSwipeLeft();
        }
    };

    const handleAutoSwipeRight = () => {
        if (surroundingPosts.prevPost) {
            setSwipeProgress(1);
            handleSwipeRight();
        }
    };

    const { reactions, selectedEmoji, handleEmojiClick } = useReactions({
        postId: post?.id ?? 0,
        apiUrl,
        initialReactions: post?.reactions.reduce(
            (acc, reaction) => ({ ...acc, [reaction.Reaction.name as keyof typeof acc]: reaction.count }),
            { happy: 0, thumbs: 0, calm: 0 }
        ),
    });

    if (!post) {
        return <Loading />;
    }

    const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(new Date(post.date));

    const backToMenu = () => {
        navigate(`/stanford`);
    };

    return (
        <div className="post-page">
            <div
                className={`post-card ${swipeDirection ? 'swipe-' + swipeDirection : ''}`}
                style={{
                    transform: `translateX(${swipeProgress * 100}%) rotate(${swipeProgress * 20}deg)`,
                    transition: swipeDirection ? 'transform 0.3s ease-in-out' : 'none',
                }}
            >
                <div className="post-header">
                    <h2>{post.title}</h2>
                    <div onClick={backToMenu}>
                        <span>Retour</span>
                        <img src="/images/arrow_back.svg" alt="Retour"/>
                    </div>
                </div>
                <p>{post.text}</p>
                <div className="swipe-area" {...swipeHandlers}>
                    {surroundingPosts.prevPost ? (
                        <span onClick={handleAutoSwipeRight}>← Avant</span>
                    ) : (
                        <span></span>
                    )}
                    {surroundingPosts.nextPost ? (
                        <span onClick={handleAutoSwipeLeft}>Après →</span>
                    ) : (
                        <span></span>
                    )}
                </div>
                <Carousel images={post.images}/>
                <div className="emoji-set">
                    <div>
                        <img
                            src={selectedEmoji.thumbs ? '/images/emoji_thumb_selected.svg' : '/images/emoji_thumb.svg'}
                            alt="Pouce"
                            onClick={() => handleEmojiClick('thumbs')}
                        />
                        <span>{reactions.thumbs}</span>
                    </div>
                    <div>
                        <img
                            src={selectedEmoji.happy ? '/images/emoji_happy_selected.svg' : '/images/emoji_happy.svg'}
                            alt="Joie"
                            onClick={() => handleEmojiClick('happy')}
                        />
                        <span>{reactions.happy}</span>
                    </div>
                    <div>
                        <img
                            src={selectedEmoji.calm ? '/images/emoji_calm_selected.svg' : '/images/emoji_calm.svg'}
                            alt="Calme"
                            onClick={() => handleEmojiClick('calm')}
                        />
                        <span>{reactions.calm}</span>
                    </div>
                </div>
                <div className="footer">
                    <span className="bar"></span>
                    <span>{formattedDate} - {post.country.name}</span>
                </div>
            </div>
        </div>
    );
};

export default PostPage;
